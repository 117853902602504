<template>
  <div class="ProductDetails layout-container">
    <!-- * * * * * * about -->
    <div class="border-b border-light">
      <div
        class="type-headline-sm select-none cursor-pointer flex justify-between md:py-24 py-16"
        @click="openItem('product-description')"
      >
        {{ $t('productPage.info.about') }}
        <img
          v-if="!openAccordionItems.includes('product-description')"
          src="/icons/plus.svg"
          class="w-20 h-20"
          alt="+"
        >
        <img
          v-else
          src="/icons/minus.svg"
          class="w-20 h-20"
          alt="-"
        >
      </div>
      <div
        class="overflow-hidden transition-all"
        :class="{
          'max-h-0 opacity-0': !openAccordionItems.includes('product-description'),
          'opacity-100 md:mb-24 mb-16': openAccordionItems.includes('product-description')
        }"
      >
        <div v-if="productItem.brand.code === '93'">
          <div :id="id" />
          <div ref="FlixMediaForm" />
        </div>
        <ProductDescription
          :product-item="productItem"
          :active-variant="activeVariant"
          class="mr-32"
        />
      </div>
    </div>
    <!-- * * * * * * ingredients -->
    <div v-if="activeVariant.ingredients" class="border-b border-light">
      <div
        class="type-headline-sm select-none cursor-pointer flex justify-between md:py-24 py-16"
        @click="openItem('ingredients')"
      >
        {{ $t('productPage.info.ingredients') }}
        <img
          v-if="!openAccordionItems.includes('ingredients')"
          src="/icons/plus.svg"
          class="w-20 h-20"
        >
        <img
          v-else
          src="/icons/minus.svg"
          class="w-20 h-20"
        >
      </div>
      <div
        class="overflow-hidden transition-all"
        :class="{
          'max-h-0 opacity-0': !openAccordionItems.includes('ingredients'),
          'max-h-[3000px] opacity-100 md:mb-24 mb-16': openAccordionItems.includes('ingredients')
        }"
      >
        <div
          class="wysiwyg type-sm mr-32"
          v-html="activeVariant.ingredients"
        />
      </div>
    </div>

    <!-- * * * * * * documents -->
    <div v-if="productItem.documentList?.length" class="border-b border-light">
      <div
        class="type-headline-sm select-none cursor-pointer flex justify-between md:py-24 py-16"
        @click="openItem('docs')"
      >
        {{ $t('productPage.info.downloadDocs') }}
        <img
          v-if="!openAccordionItems.includes('docs')"
          src="/icons/plus.svg"
          class="w-20 h-20"
        >
        <img
          v-else
          src="/icons/minus.svg"
          class="w-20 h-20"
        >
      </div>
      <div
        class="overflow-hidden transition-all"
        :class="{
          'max-h-0 opacity-0': !openAccordionItems.includes('docs'),
          'max-h-[3000px] opacity-100 md:mb-24 mb-16': openAccordionItems.includes('docs')
        }"
      >
        <div class="flex flex-wrap">
          <div
            v-for="doc in productItem.documentList"
            :key="'product-docs-' + doc.label"
            class="flex mr-24 mt-6 items-center"
          >
            <img src="/icons/file-pdf.svg" class="w-24">
            <div class="ml-6">
              <a
                :href="doc.url"
                class="underline type-base-medium type-sm"
                target="_blank"
                download
              >
                {{ doc.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- * * * * * * often bought together -->
    <div v-if="relatedList.length">
      <div
        class="type-headline-sm select-none flex justify-between md:py-24 py-16"
      >
        {{ $t('productPage.info.crossell') }}
      </div>
      <ProductRelatedProductsList :list="relatedList" :show-buy-btns="true" />
    </div>

    <ProductArticles :product-item="productItem" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ProductModel } from '~/models/product';
import ProductDescription from '~/components/product-page/ProductDescription.vue';
import ProductArticles from '~/components/product-page/ProductArticles.vue';
import ProductRelatedProductsList from '~/components/product-page/ProductRelatedProductsList.vue';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import { useProductStore } from '~/store/product';
import { useGlobalContentStore } from '~/store/globalContent';

const productStore = useProductStore();

const openAccordionItems = ref<string[]>([]);
const relatedList = ref<ProductModel[]>([]);

const props = defineProps<{
  productItem: ProductModel;
  activeVariant: ProductVariantModel,
  mobile?: boolean;
}>();

onMounted(async()=> {
  injectFlixMediaScript();
});

const eanCode = computed(() => {
  return props.productItem.eanCode;
});
watch(eanCode, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    injectFlixMediaScript();
  }
});
const id = computed(() => {
  return 'flix-inpage-' + (props.mobile ? 'mobile' : 'desktop');
});
const globalContent = useGlobalContentStore();

const FlixMediaForm = ref();
const injectFlixMediaScript = () => {
  const size = window.innerWidth < 1024 ? 'mobile' : 'desktop';
  const el = FlixMediaForm.value;
  if (!el || !eanCode.value) {
    return;
  }
  const children = Array.prototype.slice.call(el.childNodes);
  children.forEach((child) => el.removeChild(child));

  if (props.productItem?.brand?.code === '93') {
    const locale = globalContent.domainMap?.locale;
    const distributor = locale === 'no' ? '18726' : (locale === 'sv' ? '18724' : '18725');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('src', 'https://media.flixfacts.com/js/loader.js' );
    script.setAttribute('data-flix-distributor', distributor );
    script.setAttribute('data-flix-language', locale );
    script.setAttribute('data-flix-brand', 'dyson' );
    script.setAttribute('data-flix-mpn', '' );
    script.setAttribute('data-flix-ean', eanCode.value );
    script.setAttribute('data-flix-sku', '' );
    script.setAttribute('data-flix-button', 'flix-minisite' );
    script.setAttribute('data-flix-inpage', 'flix-inpage-' + size);
    script.setAttribute('data-flix-button-image', '' );
    script.setAttribute('data-flix-price', '' );
    script.setAttribute('data-flix-fallback-language', 'no' );
    el.appendChild(script);
  }

};

const openItem = (id: string) => {
  if (openAccordionItems.value.includes(id)) {
    openAccordionItems.value = openAccordionItems.value.filter(item => item !== id);
  } else {
    openAccordionItems.value = [...openAccordionItems.value, id];
  }
};

const partNos = props.productItem.relatedProductsByType(NorceRelatedProductTypeCodes.BoughtTogether);
if (partNos.length > 0) {
  relatedList.value = await productStore.getRelatedProducts(partNos);
  relatedList.value = relatedList.value.filter(data => data.variants[0].isInStock);
}
</script>
