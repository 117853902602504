<template>
  <div v-if="bundles.length" class="ProductBundles py-24 mt-24 relative mobOnly:px-12">
    <div
      v-for="(bundle, i) in bundles"
      :key="'bundle-' + i"
      class="mb-36"
    >
      <div v-if="bundle.products?.length > 0">
        <h2 class="type-headline-xl">{{ $t('productPage.bundles.title') }}</h2>
        <div
          class="grid grid-cols-2 gap-x-12 desk:gap-x-12 mt-12"
          :class="{
            'desk:grid-cols-3': bundle.products?.length > 2,
            'desk:grid-cols-2': bundle.products?.length === 2
          }"
        >
          <div
            v-for="(product, index) in bundle.products"
            :key="'bundle-product-' + index"
          >
            <BundleProductCard
              class="mr-24 last:mr-0"
              :product="product"
              :show-icon="index % 3 !== 2 && bundle.products?.length !== index + 1"
            />
          </div>
        </div>
        <Tooltip
          v-if="userStore.isBlockedCompany"
          :show-on-hover="true"
          class="ml-8 w-full "
          :text="$t('company.blocked')"
        >
          <button
            class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
            :class="{
              'pointer-events-none': true,
            }"
            :disabled="true"
          >
            {{ $t('productPage.bundles.btnText') }}
          </button>
        </Tooltip>
        <button
          v-else
          class="btn w-full mb-12"
          :class="{
            'loading': loading,
          }"
          @click="addToCart(bundle)"
        >
          {{ $t('productPage.bundles.btnText') }}
          <div v-if="userStore.isLoggedIn" class="normal-case">
            <span class="ml-8 text-12">{{ userPrice(bundle)?.price?.priceBeforeVatDisplay }}</span>
          </div>
        </button>

        <div class="w-full text-center">
          <nuxt-link
            :to="productUrl(bundle.url)"
            class="uppercase text-12 text-dark mb-4 border-b-[#d6d6d3] border-b"
          >
            {{ $t('productPage.bundles.readAboutBundle') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Product, { ProductModel } from '~/models/product';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import BundleProductCard from '~/components/product-page/BundleProductCard.vue';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { ProductRaw, StructureArticleProduct } from '~/constants/types/algolia';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { ProductVariantModel } from '~/models/productVariant';
import Tooltip from '~/components/body/Tooltip.vue';

const { getBundleProducts } = useAlgoliaFetch();
const { productUrl } = useLinkReplacer();
const userStore = useUserStore();
const cartStore = useCartStore();

const bundles = ref<{ url: string, partNo: string, products: StructureArticleProduct[] | undefined }[]>([]);

const props = defineProps<{
  activeVariant: ProductVariantModel,
}>();

const emit = defineEmits<{
  (e: 'addToCart'): void;
  (e: 'addingToCart'): void;
}>();

onMounted(async()=> {
  setTimeout(async()=> {
    await setBundles();
  }, 0);
});

const userPrice = (bundle: { partNo: string })=> {
  return userStore.getPrice(bundle.partNo);
};

const setBundles = async() => {
  const partNos = [props.activeVariant.partNo];
  const res = await getBundleProducts<ProductRaw[]>(partNos);
  if (res) {
    bundles.value = res.hits.map((bundle: ProductRaw) => Product.create(bundle))
      .filter((bundle: ProductModel) => bundle.variants[0].isInStock)
      .map((bundle: ProductModel) => {
        return {
          url: bundle.url,
          partNo: bundle.partNo,
          products: bundle.structureArticleProducts,
        };
      });
  }
};

const loading = ref(false);
const addToCart = async(bundle: any) => {
  loading.value = true;
  emit('addingToCart');

  await cartStore.updateCart(
    bundle.partNo,
    1,
    -1,
    true,
    null,
    true
  );
  emit('addToCart');

  loading.value = false;
};

</script>

<style scoped lang="postcss">
</style>
